<template>
    <div class="px-4 mt-6"> 
        <h1 class="text-2xl font-medium mb-4">{{ title }}</h1>
        <div id="data-list-list-view" class="data-list-container">
        <vs-table
            ref="table"
            :sst="true"
            :total="totalDocs"
            :data="data"
            :max-items="dataTableParams.limit"
            :noDataText="noDataText"
            @change-page="handleChangePage"
        >
            <div slot="header" class="flex items-center justify-between mb-4">
                <vs-select
                    placeholder="10"
                    vs-multiple
                    autocomplete
                    v-model="dataTableParams.limit"
                    class="mr-6"
                >
                <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in limitOptions"
                    :clearable="false"
                />
                </vs-select>
            </div>

            <template slot="thead">
                <vs-th>User</vs-th>
                <vs-th>Order Number</vs-th>
                <vs-th>Timestamp</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" class="self-center">
                    <vs-td>
                      {{ data[indextr].name || '' }} 
                    </vs-td>
                    <vs-td>
                     <vs-button type="flat" @click="viewOrder(data[indextr].orderId)"> 
                      {{ data[indextr].orderDetails ? data[indextr].orderDetails.orderNumber : '' }} 
                     </vs-button>
                    </vs-td>
                    <vs-td>
                      {{ formattedDate(data[indextr].createdAt) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <div v-if="displayAll" class="flex items-center justify-end">
          <pagination
            v-if="serverResponded"
            :hasPrev="hasPrev" 
            :hasNext="hasNext"
            :style="{ marginTop: '5px' }"
            @handleOnChangePage="handleChangePage" />
        </div>
        <template v-else>
          <span class="mr-2">
            {{
                dataTableParams.page * dataTableParams.limit -
                (dataTableParams.limit - 1)
            }}
            -
            {{
                totalDocs - dataTableParams.page * dataTableParams.limit > 0
                  ? dataTableParams.page * dataTableParams.limit
                  : totalDocs
            }}
            of {{ totalDocs }}
          </span>

          <div v-if="serverResponded" class="pagination-div">
            <paginate 
              class="pagination" 
              :page-count="totalPage" 
              :click-handler="handleChangePage" 
              :page-range="9"
              :prevText="'<'" 
              :nextText="'>'" />
          </div>
        </template>
      </div>
    </div>
  </template>

<script>
import vSelect from "vue-select";
import Pagination from "../../general-component/pagination.vue";


export default {
  name: "LogsList",
  inject: ['getRoleRouteName'],
  components: {
    "v-select": vSelect,
    Pagination
  },
  props: [
    "data", 
    "page", 
    "title",
    "totalDocs",
    "noDataText",
    "displayAll",
    "hasNext",
    "hasPrev"
  ],
  data() {
    return {
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false
    };
  },
  methods: {
    async fetchList() {
     this.$emit("fetchList", this.dataTableParams);
     this.serverResponded = true;
    },
    handleChangePage(page) {
      if (typeof page === 'string' && this.displayAll) {
        let payload = page === 'next' ? payload = {
          next: true,
          id: this.data[this.data.length - 1]._id,
          ...this.dataTableParams
        } : payload = {
          prev: true,
          id: this.data[0]._id,
          ...this.dataTableParams
        }
        this.$emit("fetchList", payload);
        return;
      }

      this.dataTableParams.page = page;
      this.fetchList();
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },
    viewOrder(orderId) {
      this.$router.push({
        name: "superAdminStoreViewOrder",
        params: { orderId }
      });
    }
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.noDataText = "Loading...";
    this.fetchList();
  }
};
</script>