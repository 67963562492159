<template>
    <LogsList
      :title="title"
      :data="data"
      :totalDocs="totalDocs"
      :page="page"
      :noDataText="noDataText"
      :hasNext="hasNext"
      :hasPrev="hasPrev"
      :displayAll="true" 
      @fetchList="getLogs"
  />
</template>

<script>
import { mapActions } from "vuex";
import LogsList from "../../components/contact-account-manager-log/list";

export default {
  components: {
    LogsList
  },
  data() {
    return {
      data: [],
      hasNext: false,
      hasPrev: false,
      noDataText: 'Loading...',
      title: "Contact Account Manager Logs",
    };
  },
  methods: {
      ...mapActions("appUser", ["fetchContactAccountManagerLogs"]),

    async getLogs(params) {
      let self = this;
      this.data = [];
      await this.fetchContactAccountManagerLogs(params).then(res => {
        self.data = res.data.data.docs;
        self.hasNext = res.data.data.hasNext;
        self.hasPrev = res.data.data.hasPrev;
        self.noDataText = "No Logs Available";
      });
    }
  }
}
</script>